import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CustomModal from "components/shared/customModal/CustomModal";
import "antd/dist/reset.css";
import { routingList } from "configs/routings/routings";
import { useForm } from "react-hook-form";
import SimpleButton from "./components/shared/buttons/simpleButton/SimpleButton";
import { api_technics } from "./api/admin/api";
import { ReactNotifications } from "react-notifications-component";

import logo from "../src/assets/images/images/studia3d_logo.png";

import "assets/styles/globalStyles.css";
import styles from "./components/components/modalChildrens/addNewModel/styles.module.scss";
import "react-notifications-component/dist/theme.css";
import "./App.css";

function App() {
  const [routing, setRouting] = useState([]);
  const [activeRoutingWindow, setActiveRoutingWindow] = useState(true);

  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [current, setCurrent] = useState(null);
  const [updates, setUpdates] = useState(0);

  const focusInput = (selector) => {
    document
      .querySelectorAll(selector)
      .forEach((el) => el.classList.add("active"));
  };

  useEffect(() => {
    setDisabled(!email || !password);
    setError(false);

    if (email.length) {
      focusInput(".login-input-wrapper.email");
    }

    if (password.length) {
      focusInput(".login-input-wrapper.password");
    }
  }, [email, password]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    const getCurrentUser = async () => {
      const id = localStorage.getItem("studia-3d-user-id");

      if (id) {
        const res = await api_technics
          .getUserById(id)
          .then((res) => setCurrent(res?.data?.data))
          .catch((e) => console.log(e));
      }
    };

    getCurrentUser();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("studia-3d-cloud-token");
    const role = current?.roles[0]?.slug;
    const path = window.location.pathname;

    if (path.includes("admin") && role === "admin") {
      handleAdminLayout();
    } else if (path.includes("user") && token) {
      handleUserLayout();
    } else if (token) {
      handleAdminLayout();
    }
  }, [current]);

  const onSubmit = async () => {
    let result = await api_technics.login({ email, password }).catch(() => {
      setError(true);
      setDisabled(true);
    });

    if (result) {
      window.location.reload();
    }
  };

  const handleAdminLayout = () => {
    setRouting(routingList.admin);
    setActiveRoutingWindow(false);
  };
  const handleUserLayout = () => {
    setRouting(routingList.user);
    setActiveRoutingWindow(false);
  };

  return (
    <>
      <ReactNotifications />
      {activeRoutingWindow && (
        <div
          style={{
            width: `100%`,
            height: `100vh`,
            display: `flex`,
            flexDirection: "column",
            justifyContent: `center`,
            alignItems: `center`,
            gap: `24px 0`,
          }}
        >
          <img className="login-logo" src={logo} />
          <div className="login-wrapper">
            <h1 className="login-title">Sign in to Tracker</h1>
            <div
              class="login-input-wrapper email"
              onFocus={(e) => e.target.parentElement.classList.add("active")}
              onBlur={(e) =>
                e.target.value.length === 0
                  ? e.target.parentElement.classList.remove("active")
                  : null
              }
            >
              <span className="login-input-wrapper-placeholder">E-mail</span>
              <input
                {...register("email")}
                value={email}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                className={`login-input ${error ? "error" : ""}`}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              class="login-input-wrapper password"
              onFocus={(e) => e.target.parentElement.classList.add("active")}
              onBlur={(e) =>
                e.target.value.length === 0
                  ? e.target.parentElement.classList.remove("active")
                  : null
              }
            >
              <span className="login-input-wrapper-placeholder">Password</span>
              <input
                {...register("password")}
                value={password}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                className={`login-input ${error ? "error" : ""}`}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            </div>
            {error ? (
              <span className="login-error-message">Authorization failed.</span>
            ) : null}

            <div
              style={{ alignSelf: "center", maxWidth: "343px", width: "100%" }}
            >
              <SimpleButton
                title={"Sign in"}
                disabled={disabled}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
      )}
      <Routes>
        {routing.map((item, i) => {
          if (item.children) {
            return (
              <>
                <Route key={i} path={item.path} element={item.element}>
                  {item.children.map((item, key) => {
                    if (item.index) {
                      return (
                        <Route
                          index
                          element={item.element}
                          key={`route_${key}`}
                        />
                      );
                    } else {
                      return (
                        <Route
                          path={item.path}
                          element={item.element}
                          key={`route_${key}`}
                        />
                      );
                    }
                  })}
                </Route>
                <Route
                  path="*"
                  element={<Navigate to={item.navigate} replace />}
                />
              </>
            );
          }
        })}
      </Routes>
      <CustomModal />
    </>
  );
}

export default App;
