import React from "react";
import { useState, useEffect } from "react";
import sorter from "assets/images/icons/sorter.svg";
import sorter_up from "assets/images/icons/sorter-up.svg";
import sorter_down from "assets/images/icons/sorter-down.svg";
import { Button, Checkbox, Popover, Spin } from "antd";
import deleteIcon from "../../../assets/images/icons/deleteIcon.svg";
import { api_details } from "api/admin/details";

import "./style.css";

const Table = ({
  title,
  header,
  width,
  data,
  fetchItems,
  activeRow,
  searchParams,
  setSearchParams,
  setOrderType,
  setOrder,
  orderType,
  order,

  filters,
  setFilters,

  enableCheckBoxes,
  checkedIds,
  setCheckedIds,
  uncheckedIds,
  setUnCheckedIds,
  uncheckedRelated,
  setUncheckedRelated,
  initialCheckedIds,
  hideId,
  loading,
  role,
}) => {
  const [sort, setSort] = useState("");
  const [currentOrderType, setCurrentOrderType] = useState("");

  useEffect(() => {
    if (order) {
      const query = Object.fromEntries([...searchParams]);
      if (query.order_type) {
        setOrderType(Object.fromEntries([...searchParams]).order_type);
      } else {
        setOrderType(null);
      }
      if (query.order && query.order !== "null") {
        setOrder(Object.fromEntries([...searchParams]).order);
      } else {
        setOrder(null);
      }
    }
  }, [
    Object.fromEntries([...searchParams]).order_type,
    Object.fromEntries([...searchParams]).order,
    Object.fromEntries([...searchParams]).limit,
    Object.fromEntries([...searchParams]).page,
  ]);

  const countMinWidth = () => {
    let count = 0;

    width.forEach((el) => {
      if (el === null) {
        count += 100;
      } else {
        count += el;
      }
    });
    return count;
  };

  const onClickSort = (order) => {
    setCurrentOrderType(order);
    setOrder(order);

    if (sort === "asc") {
      setSort("desc");
      setOrderType("desc");
    } else if (sort === "desc") {
      setSort("");
      setOrderType("");
    } else {
      setSort("asc");
      setOrderType("asc");
    }
  };

  useEffect(() => {
    setFilters({
      ...filters,
      sort: sort != "" ? currentOrderType : "",
      orderType: sort,
    });
  }, [sort]);

  return (
    <div className="tableBlock">
      <div className="customTable" style={{ minWidth: countMinWidth() }}>
        <div className="customTable_header">
          {header.map((item, i) => {
            return (
              <div
                key={`header-${i}`}
                className="p13 customTable_header__item"
                style={{
                  width: width[i] ? width[i] : "auto",
                }}
              >
                <p>{item.title}</p>
                <div onClick={() => onClickSort(item.sort_param)}>
                  {item.sort && item.title != "" ? (
                    sort === "asc" && currentOrderType === item.sort_param ? (
                      <img src={sorter_up} alt="" />
                    ) : sort === "desc" &&
                      currentOrderType === item.sort_param ? (
                      <img src={sorter_down} alt="" />
                    ) : (
                      <img src={sorter} alt="" />
                    )
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <div
            className={`customTable_body ${
              data.length === 0 && "customTable_body__notFound"
            }`}
          >
            {data.map((item, index) => {
              let idObject = item?.find((el) => el?.type === "id");

              return (
                <div
                  className={`p13 customTable_body__row ${
                    index % 2 === 0 ? "customTable_body__row_filled" : ""
                  } ${
                    idObject && activeRow?.id === idObject.content
                      ? "activeRow"
                      : ""
                  }`}
                  key={`data-${index}`}
                  onClick={() => fetchItems && fetchItems(item)}
                >
                  {enableCheckBoxes ? (
                    <Checkbox
                      style={{ marginRight: 15 }}
                      checked={
                        !!checkedIds.find((id) => id == idObject.content)
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckedIds([...checkedIds, idObject.content]);

                          let removeFromUnchecked = uncheckedIds?.filter(
                            (id) => id != idObject.content
                          );

                          if (setUnCheckedIds) {
                            setUnCheckedIds(removeFromUnchecked);
                          }

                          if (
                            !!uncheckedRelated?.find(
                              (id) => id == idObject.content
                            ) == true
                          ) {
                            let uncheckedRelatedFilter =
                              uncheckedRelated.filter(
                                (id) => id != idObject.content
                              );

                            setUncheckedRelated(uncheckedRelatedFilter);
                          }
                        } else {
                          let newArr = checkedIds?.filter(
                            (id) => id != idObject.content
                          );

                          if (
                            uncheckedRelated &&
                            setUncheckedRelated &&
                            !!uncheckedRelated?.find(
                              (id) => id == idObject.content
                            ) == false
                          )
                            setUncheckedRelated([
                              ...uncheckedRelated,
                              idObject.content,
                            ]);

                          setCheckedIds(newArr);

                          if (
                            initialCheckedIds &&
                            !!initialCheckedIds.find(
                              (id) => id == idObject.content
                            ) &&
                            setUnCheckedIds
                          ) {
                            setUnCheckedIds([
                              ...uncheckedIds,
                              idObject.content,
                            ]);
                          }
                        }
                      }}
                    />
                  ) : null}

                  {item.map((item, key) => {
                    if (item.type === "text" || item.type === "id") {
                      if (item.type === "id" && hideId) return;
                      return (
                        <p
                          key={`item-${index}_${key}`}
                          style={{ width: width[key] ? width[key] : "auto" }}
                          className="customTable_body__item"
                        >
                          {item.content}
                        </p>
                      );
                    } else if (item.type === "actions") {
                      if (role === "admin") {
                        return (
                          <div
                            className="customTable_body__row__actionBlock"
                            key={`item-${index}_${key}`}
                            style={{
                              width: width[key] ? width[key] : "auto",
                              flexGrow: 1,
                            }}
                            onClick={item.action}
                          >
                            <img src={item.content} alt="" />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              width: width[key] ? width[key] : "auto",
                              flexGrow: 1,
                            }}
                          ></div>
                        );
                      }
                    } else if (item.type === "actions-remove") {
                      if (role === "admin") {
                        return (
                          <div
                            className="customTable_body__row__actionBlock"
                            key={`item-${index}_${key}`}
                            style={{
                              width: width[key] ? width[key] : "auto",
                              flexGrow: 1,
                            }}
                            onClick={item.action}
                          >
                            {item.content}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              width: width[key] ? width[key] : "auto",
                              flexGrow: 1,
                            }}
                          ></div>
                        );
                      }
                    } else if (item.type === "status") {
                      return (
                        <p
                          key={`item-${index}_${key}`}
                          style={{
                            width: width[key] ? width[key] : "auto",
                          }}
                          className="customTable_body__item"
                        >
                          <span
                            className="customTable_body__item-status"
                            style={{ backgroundColor: item.color }}
                          >
                            {item.content}
                          </span>
                        </p>
                      );
                    } else if (item.type === "color") {
                      return (
                        <p
                          key={`item-${index}_${key}`}
                          style={{
                            width: width[key] ? width[key] : "auto",
                          }}
                          className="customTable_body__item"
                        >
                          <span
                            className="customTable_body__item-color"
                            style={{ backgroundColor: item.content }}
                          ></span>
                        </p>
                      );
                    } else if (item.type === "add-model") {
                      if (role === "admin") {
                        return (
                          <button
                            key={`item-${index}_${key}`}
                            onClick={item.action}
                            className="add-model-btn"
                            style={{
                              width: width[key] ? width[key] : "auto",
                            }}
                          >
                            Add model(s)
                          </button>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              width: width[key] ? width[key] : "auto",
                              flexGrow: 1,
                            }}
                          ></div>
                        );
                      }
                    }
                  })}
                </div>
              );
            })}
            {data.length === 0 && <div>Data not found</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
