import UserLayout from "components/layout/user/UserLayout";
import busModel from "../../assets/images/testImages/busModel.png";
import ModelWindow from "../../components/ui/modelCard/modelWindow/ModelWindow";
import React from "react";

export const userRoutings = [
  {
    path: "/user/",
    element: <UserLayout />,
    navigate: "/user/model",
    children: [
      {
        path: "model/:id",
        index: false,
        element: <ModelWindow img={busModel} data={{}} />,
      },
    ],
  },
];
