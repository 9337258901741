import DrawerMenu from "components/shared/drawerMenu/DrawerMenu";
import logo from "assets/images/images/studia3d_logo.png";
import menu from "assets/images/icons/menu.svg";
import exit from "assets/images/icons/exitIcon.svg";
import angleDown from "assets/images/icons/angleDown.svg";
import { menuList } from "data/routings";
import { Dropdown } from "antd";
import "./style.css";
import "../../media.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { api_technics } from "../../../../api/admin/api";

export function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const [role, setRole] = useState(null);

  const onSubmit = async (_) => {
    await api_technics.logout();
    window.location.reload();
  };

  useEffect(() => {
    const id = localStorage.getItem("studia-3d-user-id");

    api_technics
      .getUserById(id)
      .then(() => setRole("admin"))
      .catch(() => setRole("manager"));
  }, []);

  const items = [
    {
      label: (
        <p className="headerContainer_dropDownMenu" onClick={onSubmit}>
          <img src={exit} />
          <span className="p15">Log out</span>
        </p>
      ),
    },
  ];

  return (
    <div className="headerContainer">
      <div className="headerContainer_leftPart">
        {location.pathname !== "/admin/my-model" &&
          location.pathname !== "/admin/my-models/new-model" && (
            <>
              <img
                src={menu}
                alt="menu"
                onClick={() => setShowMenu(!showMenu)}
                className="headerContainer_menuIcon"
              />

              <DrawerMenu
                menuList={menuList}
                setShowMenu={setShowMenu}
                showMenu={showMenu}
              />
            </>
          )}
        <img className="header__logo" src={logo} alt="logo" />
      </div>

      <Dropdown menu={{ items }} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <div className="headerContainer_rightPart">
            <div className="p13 headerContainer_name">{role || "Admin"}</div>
            <img src={angleDown} alt="angleDown" />
          </div>
        </a>
      </Dropdown>
    </div>
  );
}
